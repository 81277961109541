import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getToken } from '../user/User'

export const useAutomaticMailing = (id) => {
    const [recipient, setRecipient] = useState("")
    const [mailTemplate, setMailTemplate] = useState(0)
    const [provider, setProvider] = useState(0)
    const [duration, setDuration] = useState(0)
    const [daysUntilExpiry, setDaysUntilExpiry] = useState(1)

    useEffect(() => {
        if(id !== null && id !== undefined) {
            const abortCont = new AbortController()
            
            axios.get(process.env.REACT_APP_API_URL + "/api/automaticmailings/" + id, {
                signal: abortCont.signal,
                headers: {
                    "Authorization": "Bearer " + getToken()
                }
            })
            .then(res => {
                var data = res.data

                setRecipient(data.recipient)
                setMailTemplate(data.mail_template)
                setProvider(data.provider)
                setDuration(data.duration)
                setDaysUntilExpiry(data.days_until_expiry)
            }).catch(err => {
                if(err.message === "canceled") {
                    return
                }
                toast.error("Internal error")
            })
        
            return () => abortCont.abort()
        }
    }, [id])

    return { id, recipient, setRecipient, mailTemplate, setMailTemplate, provider, setProvider, duration, setDuration, daysUntilExpiry, setDaysUntilExpiry }
}