import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router"
import { getId, getToken, usePermissions } from "../../user/User"
import { toast } from "react-toastify"
import { useEffect } from "react"
import axios from 'axios'
import { useMailTemplate } from '../../mailtemplate/MailTemplate'

const MailTemplateDelete = () => {
    const navigate = useNavigate()

    let { id } = useParams()
    let { name } = useMailTemplate(id)

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.client === undefined) {
            return
        }
        if(!permissions.client) {
            toast.error("You don't have permission to delete mail templates!")
            navigate("/")
        } else {
            if (confirm('Do you really want to delete this mail template?')) {
                axios.delete(process.env.REACT_APP_API_URL + "/api/mailtemplates/" + id, { headers: {
                    "Authorization": "Bearer " + getToken()
                }}).then(_ => {
                    toast.success("Deleted mail template " + name + " #" + id + ".")
                    navigate("/mailtemplate")
                }).catch(error => {
                    if (error.response) {
                        var statusCode = error.response.status
                        if(statusCode === 400) {
                            navigate("/mailtemplate")
                            toast.error("Invalid request.")
                            return
                        } else if(statusCode === 404) {
                            navigate("/mailtemplate")
                            toast.error("Unable to find mail template #" + id + ".")
                            return
                        } else if(statusCode === 409) {
                            navigate("/mailtemplate")
                            toast.error("The mail template " + name + " #" + id + " is still used.")
                            return
                        }
                    }
                    navigate("/mailtemplate")
                    toast.error("Internal error.")
                })
            } else {
                navigate("/mailtemplate")
            }
        }
    }, [permissions])

    return (
        <>
            <p>Deleting mail template...</p>
        </>
    )
}

export default MailTemplateDelete