import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router"
import { getId, getToken, usePermissions } from "../../user/User"
import { toast } from "react-toastify"
import { useEffect } from "react"
import { useProvider } from '../../provider/Provider'
import axios from 'axios'

const ProviderDelete = () => {
    const navigate = useNavigate()

    let { id } = useParams()
    let { name } = useProvider(id)

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.client === undefined) {
            return
        }
        if(!permissions.client) {
            toast.error("You don't have permission to delete providers!")
            navigate("/")
        } else {
            if (confirm('Do you really want to delete this provider?')) {
                axios.delete(process.env.REACT_APP_API_URL + "/api/providers/" + id, { headers: {
                    "Authorization": "Bearer " + getToken()
                }}).then(_ => {
                    toast.success("Deleted provider " + name + " #" + id + ".")
                    navigate("/provider")
                }).catch(error => {
                    if (error.response) {
                        var statusCode = error.response.status
                        if(statusCode === 400) {
                            navigate("/provider")
                            toast.error("Invalid request.")
                            return
                        } else if(statusCode === 404) {
                            navigate("/provider")
                            toast.error("Unable to find provider #" + id + ".")
                            return
                        } else if(statusCode === 409) {
                            navigate("/provider")
                            toast.error("The provider " + name + " #" + id + " is still used.")
                            return
                        }
                    }
                    navigate("/provider")
                    toast.error("Internal error.")
                })
            } else {
                navigate("/provider")
            }
        }
    }, [permissions])

    return (
        <>
            <p>Deleting provider...</p>
        </>
    )
}

export default ProviderDelete