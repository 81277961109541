import axios from "axios"
import { useState } from "react"
import Autocomplete from "react-autocomplete"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { getToken } from "../user/User"

const Navbar = () => {
  const [client, setClient] = useState("")
  const [clients, setClients] = useState([])

  const navigate = useNavigate()

  const logout = () => {
    sessionStorage.clear()
    localStorage.clear()
    navigate("/login")
  }

  const gotoSettings = () => {
    navigate("/settings")
  }

  async function updateClient(newClientSearch) {
    setClient(newClientSearch)
    if(newClientSearch === "") {
        return
    }
    axios.get(process.env.REACT_APP_API_URL + "/api/clients/search/" + newClientSearch + "?limit=10", {
        headers: {
            "Authorization": "Bearer " + getToken()
        }
    }).then(res => {
        if(res.data === null || res.data === "") {
            return
        }
        setClients(res.data)
    }).catch(error => {
      console.log(error)
      if (error.response) {
            var statusCode = error.response.status
            if(statusCode === 400) {
                toast.error("Invalid request.")
                return
            }
        }
        toast.error("Internal error")
    })
  }

  async function openClient(clientId) {
    navigate("/client/" + clientId)
  }

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <p className="nav-link" data-widget="pushmenu" role="button"><i className="fas fa-bars"></i></p>
        </li>

        <li className="nav-item">
          <div className="nav-link">
            <Autocomplete
              wrapperStyle={{cursor: "pointer", float: "left"}}
              getItemValue={(item) => item.id + ""}
              items={clients.map(client => {
                  return client
              })}
              renderItem={(client, isHighlighted) =>
                  <div key={client.id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                      {client.username + " (" + client.email + ")"}
                  </div>
              }
              renderInput={(params) => (
                <input {...params} placeholder="Search client" />
              )}
              value={client}
              onChange={(e) => updateClient(e.target.value)}
              onSelect={(selected) => openClient(selected)}
            />
          </div>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <p className="nav-link" role="button" onClick={gotoSettings}>
            <i className="fas fa-gear"></i>
          </p>
        </li>
        <li className="nav-item">
          <p className="nav-link" data-widget="fullscreen" role="button">
            <i className="fas fa-expand-arrows-alt"></i>
          </p>
        </li>
        <li className="nav-item">
          <p className="nav-link" role="button" onClick={logout}>
            <i className="fa-solid fa-right-from-bracket"></i>
          </p>
        </li>
      </ul>
    </nav>
  )
}
 
export default Navbar