import axios from "axios"
import { useEffect, useState } from "react"
import FormButton from "../../components/FormButton"
import { getToken } from "../../user/User"
import Select from "react-select"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const AutomaticMailingDetailView = ({ id, recipient, setRecipient, mailTemplate, setMailTemplate, provider, setProvider, duration, setDuration, daysUntilExpiry, setDaysUntilExpiry }) => {
    const navigate = useNavigate()

    const [providerSelect, setProviderSelect] = useState(<></>)
    const [mailTemplateSelect, setMailTemplateSelect] = useState(<></>)
    const [recipientSelect, setRecipientSelect] = useState(<></>)
    const [durationSelect, setDurationSelect] = useState(<></>)
	useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/providers", { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            const options = []
            options.push({
                value: 0,
                label: "All"
            })
            res.data.forEach((provider, _) => {
                options.push({
                    value: provider.id,
                    label: provider.name
                })
            })

            setProviderSelect(<Select options={options} onChange={(choice) => setProvider(choice.value)} value={options.filter(option => option.value == provider)} />)
        }).catch(error => {
            console.log(error)
            toast.error("Unable to load providers")
        })

        axios.get(process.env.REACT_APP_API_URL + "/api/mailtemplates", { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            const options = []
            res.data.forEach((mailTemplate, _) => {
                options.push({
                    value: mailTemplate.id,
                    label: mailTemplate.name
                })
            })

            setMailTemplateSelect(<Select options={options} onChange={(choice) => setMailTemplate(choice.value)} value={options.filter(option => option.value == mailTemplate)} />)
        }).catch(error => {
            console.log(error)
            toast.error("Unable to load mail templates")
        })

        const recipientOptions = [
            {
                value: "client",
                label: "Client"
            },
            {
                value: "admin",
                label: "Admin"
            }
        ]
        setRecipientSelect(<Select options={recipientOptions} onChange={(choice) => setRecipient(choice.value)} value={recipientOptions.filter(option => option.value == recipient)} />)
        
        const durationOptions = [
            {
                value: 0,
                label: "All"
            },
            {
                value: 1,
                label: "1 month"
            },
            {
                value: 3,
                label: "3 months"
            },
            {
                value: 6,
                label: "6 months"
            },
            {
                value: 12,
                label: "12 months"
            },
            {
                value: 24,
                label: "24 months"
            }
        ]
        setDurationSelect(<Select options={durationOptions} onChange={(choice) => setDuration(choice.value)} value={durationOptions.filter(option => option.value == duration)} />)
    }, [provider, mailTemplate, recipient, duration])
    
    const onSave = async e => {
        e.preventDefault()

        axios.put(process.env.REACT_APP_API_URL + "/api/automaticmailings/" + id, JSON.stringify({
            "recipient": recipient,
            "mail_template": parseInt(mailTemplate),
            "provider": parseInt(provider),
            "duration": duration,
            "days_until_expiry": daysUntilExpiry
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            toast.success("Saved automatic mailing #" + id + ".")
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 404) {
                    toast.error("Unable to find automatic mailing #" + id + ".")
                    return
                } else if(statusCode === 409) {
                    toast.error("There is an automatic mailing with the same configuration.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    const onDelete = async e => {
        e.preventDefault()
        if (confirm('Do you really want to delete this automatic mailing?')) {
            axios.delete(process.env.REACT_APP_API_URL + "/api/automaticmailings/" + id, { headers: {
                "Authorization": "Bearer " + getToken()
            }}).then(_ => {
                toast.success("Deleted automatic mailing #" + id + ".")
                navigate("/automaticmailing")
            }).catch(error => {
                if (error.response) {
                    var statusCode = error.response.status
                    if(statusCode === 400) {
                        toast.error("Invalid request.")
                        return
                    } else if(statusCode === 404) {
                        toast.error("Unable to find automatic mailing #" + id + ".")
                        return
                    }
                }
                toast.error("Internal error.")
            })
        }
    }

    return (
        <>
            <form className="form-horizontal">
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                    <b>Recipient</b>
                                    {recipientSelect}
                                    <br />
                                </div>
                                <div className="col-lg-6 col-12">
                                    <b>Mail template</b>
                                    {mailTemplateSelect}
                                    <br />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <b>Provider</b>
                                    {providerSelect}
                                    <br />
                                </div>
                                <div className="col-lg-6 col-12">
                                    <b>Duration</b>
                                    {durationSelect}
                                    <br />
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <FormButton label="Days until expiry" id="daysUntilExpiry" type="number" value={daysUntilExpiry} setValue={setDaysUntilExpiry} icon="fa-solid fa-clock" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        <button className="btn btn-primary" onClick={onSave}>Save</button>
                        <button className="btn btn-danger" onClick={onDelete}>Delete</button>
                    </div>
                </div>
            </form>
        </>
    )
}
   
export default AutomaticMailingDetailView