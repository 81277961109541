import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router"
import { getId, usePermissions } from "../../user/User"
import { toast } from "react-toastify"
import { useEffect } from "react"
import { useClient } from "../../client/Client"
import ClientDetailView from './ClientDetailView'

const ClientEdit = () => {
    const navigate = useNavigate()

    let { id } = useParams()
    let { username, setUsername, emailAddress, setEmailAddress, provider, setProvider, duration, setDuration, expiryDate, setExpiryDate } = useClient(id)

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.client !== undefined && !permissions.client) {
            toast.error("You don't have permission to edit clients!")
            navigate("/")
        }
    }, [permissions])

    const handleBack = async e => {
        e.preventDefault()
        navigate("/client")
    }

    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Back to overview</button><br /><br />

            <ClientDetailView id={id} username={username} setUsername={setUsername} emailAddress={emailAddress} setEmailAddress={setEmailAddress}
                provider={provider} setProvider={setProvider} duration={duration} setDuration={setDuration} expiryDate={expiryDate} setExpiryDate={setExpiryDate} />
        </>
    )
}

export default ClientEdit