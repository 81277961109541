import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router"
import { getId, getToken, usePermissions } from "../../user/User"
import { toast } from "react-toastify"
import { useEffect } from "react"
import { useClient } from "../../client/Client"
import axios from 'axios'

const ClientDelete = () => {
    const navigate = useNavigate()

    let { id } = useParams()
    let { username } = useClient(id)

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.client === undefined) {
            return
        }
        if(!permissions.client) {
            toast.error("You don't have permission to delete clients!")
            navigate("/")
        } else {
            if (confirm('Do you really want to delete this client?')) {
                axios.delete(process.env.REACT_APP_API_URL + "/api/clients/" + id, { headers: {
                    "Authorization": "Bearer " + getToken()
                }}).then(_ => {
                    toast.success("Deleted client " + username + " #" + id + ".")
                    navigate("/client")
                }).catch(error => {
                    if (error.response) {
                        var statusCode = error.response.status
                        if(statusCode === 400) {
                            navigate("/client")
                            toast.error("Invalid request.")
                            return
                        } else if(statusCode === 404) {
                            navigate("/client")
                            toast.error("Unable to find client #" + id + ".")
                            return
                        }
                    }
                    navigate("/client")
                    toast.error("Internal error.")
                })
            } else {
                navigate("/client")
            }
        }
    }, [permissions])

    return (
        <>
            <p>Deleting client...</p>
        </>
    )
}

export default ClientDelete