import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import FormButton from "../../components/FormButton"
import { getId, getToken, usePermissions } from "../../user/User"
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import draftToHtml from 'draftjs-to-html'

const MailTemplateCreate = () => {
    const navigate = useNavigate()

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.client !== undefined && !permissions.client) {
            toast.error("You don't have permission to create mail templates!")
            navigate("/")
            return
        }
    }, [permissions])

    const [name, setName] = useState("")
    const [subject, setSubject] = useState("")
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    async function createMailTemplate() {
        axios.post(process.env.REACT_APP_API_URL + "/api/mailtemplates", JSON.stringify({
            "name": name,
            "subject": subject,
            "body": draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            toast.success("Created mail template " + name + " (#" + res.data.id + ").")
            navigate("/mailtemplate/" + res.data.id)
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 409) {
                    toast.error("There is a mail template with the same name.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        createMailTemplate()
    }

    const handleBack = async e => {
        e.preventDefault()
        navigate("/mailtemplate")
    }

    const onEditorStateChange = async state => {
        setEditorState(state)
    }

    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Back to overview</button><br /><br />

            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <FormButton label="Name" id="name" value={name} setValue={setName} icon="fa-solid fa-envelope" labelSize="col-lg-3" size="col-lg-7" />
                                </div>
                                <div className="col-lg-6 col-12">
                                    <FormButton label="Subject" id="subject" value={subject} setValue={setSubject} icon="fa-solid fa-text" labelSize="col-lg-3" size="col-lg-7" />
                                </div>
                                <div className="col-lg-6 col-12">
                                    <p>You can use the following placeholders for client emails: {"{{.Clientname}}, {{.EmailAddress}}, {{.Provider}}, {{.Duration}}, {{.ExpiryDate}}"}</p>
                                    <p>And the following addotional for purchase emails: {"{{.Username}}, {{.Password}}, {{.M3uUrl}}, {{.UserId}}, {{.Message}}"}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <Editor editorState={editorState} onEditorStateChange={onEditorStateChange} />
                                </div>
                                <div className="col-lg-6 col-12">
                                    <p>Preview</p>
                                    <div style={{border: "3px solid black"}} dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        <button type="submit" className="btn btn-success">Create mail template</button>
                    </div>
                </div>
            </form>
        </>
    )
}
   
export default MailTemplateCreate