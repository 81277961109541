import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Select from 'react-select'
import { toast } from "react-toastify"
import FormButton from "../../components/FormButton"
import { getId, getToken, usePermissions } from "../../user/User"

const AutomaticMailingCreate = () => {
    const navigate = useNavigate()
    const providerOptions = []
    const mailTemplateOptions = []
    const recipientOptions = [
        {
            value: "client",
            label: "Client"
        },
        {
            value: "admin",
            label: "Admin"
        }
    ]
    const durationOptions = [
        {
            value: 0,
            label: "All"
        },
        {
            value: 1,
            label: "1 month"
        },
        {
            value: 3,
            label: "3 months"
        },
        {
            value: 6,
            label: "6 months"
        },
        {
            value: 12,
            label: "12 months"
        },
        {
            value: 24,
            label: "24 months"
        }
    ]

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.client !== undefined && !permissions.client) {
            toast.error("You don't have permission to create automatic mailings!")
            navigate("/")
            return
        }
        
        axios.get(process.env.REACT_APP_API_URL + "/api/providers", { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            providerOptions.push({
                value: 0,
                label: "All"
            })
            if(res.data == null) {
                return
            }
            res.data.forEach((provider, _) => {
                providerOptions.push({
                    value: provider.id,
                    label: provider.name
                })
            })
        }).catch(error => {
            console.log(error)
            toast.error("Unable to load providers")
        })
        
        axios.get(process.env.REACT_APP_API_URL + "/api/mailtemplates", { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            if(res.data == null) {
                return
            }
            res.data.forEach((mailTemplate, _) => {
                mailTemplateOptions.push({
                    value: mailTemplate.id,
                    label: mailTemplate.name
                })
            })
        }).catch(error => {
            console.log(error)
            toast.error("Unable to load mail templates")
        })
    })

    const [recipient, setRecipient] = useState("")
    const [mailTemplate, setMailTemplate] = useState(0)
    const [provider, setProvider] = useState(0)
    const [duration, setDuration] = useState(0)
    const [daysUntilExpiry, setDaysUntilExpiry] = useState(1)

    async function createAutomaticMailing() {
        if(recipient === "") {
            toast.error("Please select a recipent")
            return
        }
        if(mailTemplate === 0) {
            toast.error("Please select a mail template")
            return
        }

        axios.post(process.env.REACT_APP_API_URL + "/api/automaticmailings", JSON.stringify({
            "recipient": recipient,
            "mail_template": mailTemplate,
            "provider": provider,
            "duration": duration,
            "days_until_expiry": parseInt(daysUntilExpiry)
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            toast.success("Created automatic mailing #" + res.data.id + ".")
            navigate("/automaticmailing/" + res.data.id)
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 409) {
                    toast.error("There is an automatic mailing with the same configuration.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        createAutomaticMailing()
    }

    const handleBack = async e => {
        e.preventDefault()
        navigate("/automaticmailing")
    }

    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Back to overview</button><br /><br />

            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <b>Recipient</b>
                                    <Select options={recipientOptions} onChange={(choice) => setRecipient(choice.value)} />
                                    <br />
                                </div>
                                <div className="col-lg-6 col-12">
                                    <b>Mail template</b>
                                    <Select options={mailTemplateOptions} onChange={(choice) => setMailTemplate(choice.value)} />
                                    <br />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <b>Provider</b>
                                    <Select options={providerOptions} onChange={(choice) => setProvider(choice.value)} />
                                    <br />
                                </div>
                                <div className="col-lg-6 col-12">
                                    <b>Duration</b>
                                    <Select options={durationOptions} onChange={(choice) => setDuration(choice.value)} />
                                    <br />
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <FormButton label="Days until expiry" id="daysUntilExpiry" type="number" value={daysUntilExpiry} setValue={setDaysUntilExpiry} icon="fa-solid fa-clock" min="1"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        <button type="submit" className="btn btn-success">Create automatic mailing</button>
                    </div>
                </div>
            </form>
        </>
    )
}
   
export default AutomaticMailingCreate