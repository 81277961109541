import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Select from 'react-select'
import { toast } from "react-toastify"
import FormButton from "../../components/FormButton"
import { getId, getToken, usePermissions } from "../../user/User"

const MailingCreate = () => {
    const navigate = useNavigate()
    const providerOptions = []
    const mailTemplateOptions = []
    const recipientOptions = [
        {
            value: "client",
            label: "Client"
        },
        {
            value: "admin",
            label: "Admin"
        }
    ]

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.client !== undefined && !permissions.client) {
            toast.error("You don't have permission to create mailings!")
            navigate("/")
        }
    }, [permissions])
    
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/providers", { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            providerOptions.push({
                value: 0,
                label: "All"
            })
            if(res.data == null) {
                return
            }
            res.data.forEach((provider, _) => {
                providerOptions.push({
                    value: provider.id,
                    label: provider.name
                })
            })
        }).catch(error => {
            console.log(error)
            toast.error("Unable to load providers")
        })
        
        axios.get(process.env.REACT_APP_API_URL + "/api/mailtemplates", { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            if(res.data == null) {
                return
            }
            res.data.forEach((mailTemplate, _) => {
                mailTemplateOptions.push({
                    value: mailTemplate.id,
                    label: mailTemplate.name
                })
            })
        }).catch(error => {
            console.log(error)
            toast.error("Unable to load mail templates")
        })
    })

    const [recipient, setRecipient] = useState("")
    const [mailTemplate, setMailTemplate] = useState(0)
    const [provider, setProvider] = useState(0)
    const [daysUntilExpiry, setDaysUntilExpiry] = useState(1)
    const [duration1Month, setDuration1Month] = useState(false)
    const [duration3Months, setDuration3Months] = useState(false)
    const [duration6Months, setDuration6Months] = useState(false)
    const [duration12Months, setDuration12Months] = useState(false)
    const [duration24Months, setDuration24Months] = useState(false)

    async function createMailing() {
        if(recipient === "") {
            toast.error("Please select a recipent")
            return
        }
        if(mailTemplate === 0) {
            toast.error("Please select a mail template")
            return
        }

        let durations = []
        if(duration1Month) {
            durations.push(1)
        }
        if(duration3Months) {
            durations.push(3)
        }
        if(duration6Months) {
            durations.push(6)
        }
        if(duration12Months) {
            durations.push(12)
        }
        if(duration24Months) {
            durations.push(24)
        }
        axios.post(process.env.REACT_APP_API_URL + "/api/mailing", JSON.stringify({
            "recipient": recipient,
            "mail_template": mailTemplate,
            "provider": provider,
            "durations": durations,
            "days_until_expiry": parseInt(daysUntilExpiry)
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            toast.success("Scheduled a mailing to all " + recipient + "s with template '" + mailTemplate + "' for provider='" + provider + "', days until expiry='" + daysUntilExpiry + "' and durations='" + durations.join(', ') + "'.")
            navigate("/")
        }).catch(error => {
            if(error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        createMailing()
    }

    function toggleDuration(duration, toggle) {
        switch (duration) {
            case 1:
                setDuration1Month(toggle);
                break;
            case 3:
                setDuration3Months(toggle);
                break;
            case 6:
                setDuration6Months(toggle);
                break;
            case 12:
                setDuration12Months(toggle);
                break;
            case 24:
                setDuration24Months(toggle);
                break;
        }
    }

    return (
        <>
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <b>Recipient</b>
                                    <Select options={recipientOptions} onChange={(choice) => setRecipient(choice.value)} />
                                    <br />
                                </div>
                                <div className="col-lg-6 col-12">
                                    <b>Mail template</b>
                                    <Select options={mailTemplateOptions} onChange={(choice) => setMailTemplate(choice.value)} />
                                    <br />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <b>Provider</b>
                                    <Select options={providerOptions} onChange={(choice) => setProvider(choice.value)} />
                                    <br />
                                </div>
                                <div className="col-lg-6 col-12">
                                    <FormButton label="Days until expiry" id="daysUntilExpiry" type="number" value={daysUntilExpiry} setValue={setDaysUntilExpiry} icon="fa-solid fa-clock" min="1" />
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <b>Duration</b>
                                    <br />

                                    <input type="checkbox" id="duration1Month" value={duration1Month} onChange={e => toggleDuration(1, e.target.checked)} />
                                    <label htmlFor="duration1Month" style={{margin: "10px"}}>
                                        {" 1 month"}
                                    </label>

                                    <input type="checkbox" id="duration3Months" value={duration3Months} onChange={e => toggleDuration(3, e.target.checked)} />
                                    <label htmlFor="duration3Months" style={{margin: "10px"}}>
                                        {" 3 months"}
                                    </label>

                                    <input type="checkbox" id="duration6Months" value={duration6Months} onChange={e => toggleDuration(6, e.target.checked)} />
                                    <label htmlFor="duration6Months" style={{margin: "10px"}}>
                                        {" 6 months"}
                                    </label>

                                    <input type="checkbox" id="duration12Months" value={duration12Months} onChange={e => toggleDuration(12, e.target.checked)} />
                                    <label htmlFor="duration12Months" style={{margin: "10px"}}>
                                        {" 12 months"}
                                    </label>

                                    <input type="checkbox" id="duration24Months" value={duration24Months} onChange={e => toggleDuration(24, e.target.checked)} />
                                    <label htmlFor="duration24Months" style={{margin: "10px"}}>
                                        {" 24 months"}
                                    </label>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        <button type="submit" className="btn btn-success">Schedule mailing</button>
                    </div>
                </div>
            </form>
        </>
    )
}
   
export default MailingCreate
