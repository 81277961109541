import axios from "axios"
import FormButton from "../../components/FormButton"
import { getToken } from "../../user/User"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import draftToHtml from 'draftjs-to-html'
import { useEffect, useState } from "react"
import htmlToDraft from 'html-to-draftjs'

const MailTemplateDetailView = ({ id, name, setName, subject, setSubject, body, setBody }) => {
    const navigate = useNavigate()
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    useEffect(() => {
        const contentBlock = htmlToDraft(body);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
        }
    }, [body])

    const onSave = async e => {
        e.preventDefault()

        axios.put(process.env.REACT_APP_API_URL + "/api/mailtemplates/" + id, JSON.stringify({
            "name": name,
            "subject": subject,
            "body": draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            toast.success("Saved mail template " + name + " (#" + id + ").")
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 404) {
                    toast.error("Unable to find mail template #" + id + ".")
                    return
                } else if(statusCode === 409) {
                    toast.error("There is a mail template with the same name.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    const onDelete = async e => {
        e.preventDefault()
        if (confirm('Do you really want to delete this mail template?')) {
            axios.delete(process.env.REACT_APP_API_URL + "/api/mailtemplates/" + id, { headers: {
                "Authorization": "Bearer " + getToken()
            }}).then(_ => {
                toast.success("Deleted mail template " + name + " #" + id + ".")
                navigate("/mailtemplate")
            }).catch(error => {
                if (error.response) {
                    var statusCode = error.response.status
                    if(statusCode === 400) {
                        toast.error("Invalid request.")
                        return
                    } else if(statusCode === 404) {
                        toast.error("Unable to find mail template #" + id + ".")
                        return
                    }
                }
                toast.error("Internal error.")
            })
        }
    }

    const onEditorStateChange = async state => {
        setEditorState(state)
    }

    return (
        <>
            <form className="form-horizontal">
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <FormButton label="Name" id="name" value={name} setValue={setName} icon="fa-solid fa-envelope" labelSize="col-lg-3" size="col-lg-7" />
                                </div>
                                <div className="col-lg-12 col-12">
                                    <FormButton label="Subject" id="subject" value={subject} setValue={setSubject} icon="fa-solid fa-text" labelSize="col-lg-3" size="col-lg-7" />
                                </div>
                                <div className="col-lg-12 col-12">
                                    <p>You can use the following placeholders for client emails: {"{{.Clientname}}, {{.EmailAddress}}, {{.Provider}}, {{.Duration}}, {{.ExpiryDate}}"}</p>
                                    <p>And the following addotional for purchase emails: {"{{.Username}}, {{.Password}}, {{.M3uUrl}}, {{.UserId}}, {{.Message}}"}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <Editor editorState={editorState} onEditorStateChange={onEditorStateChange} />
                                </div>
                                <div className="col-lg-6 col-12">
                                    <p>Preview</p>
                                    <div style={{border: "3px solid black"}} dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        <button className="btn btn-primary" onClick={onSave}>Save</button>
                        <button className="btn btn-danger" onClick={onDelete}>Delete</button>
                    </div>
                </div>
            </form>
        </>
    )
}
   
export default MailTemplateDetailView