import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router"
import { getId, usePermissions } from "../../user/User"
import { toast } from "react-toastify"
import { useEffect } from "react"
import { useAutomaticMailing } from '../../automaticmailing/AutomaticMailing'
import AutomaticMailingDetailView from './AutomaticMailingDetailView'

const AutomaticMailingEdit = () => {
    const navigate = useNavigate()

    let { id } = useParams()
    let { recipient, setRecipient, mailTemplate, setMailTemplate, provider, setProvider, duration, setDuration, daysUntilExpiry, setDaysUntilExpiry } = useAutomaticMailing(id)

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.client !== undefined && !permissions.client) {
            toast.error("You don't have permission to edit automatic mailings!")
            navigate("/")
        }
    }, [permissions])

    const handleBack = async e => {
        e.preventDefault()
        navigate("/automaticmailing")
    }

    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Back to overview</button><br /><br />

            <AutomaticMailingDetailView id={id} recipient={recipient} setRecipient={setRecipient} mailTemplate={mailTemplate} setMailTemplate={setMailTemplate}
                provider={provider} setProvider={setProvider} duration={duration} setDuration={setDuration}
                daysUntilExpiry={daysUntilExpiry} setDaysUntilExpiry={setDaysUntilExpiry} />
        </>
    )
}

export default AutomaticMailingEdit