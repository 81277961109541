import axios from "axios"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getId, getToken, usePermissions } from "../../user/User"
import BasicTableComponent, { NumberRangeColumnFilter } from "../../components/basic.table"
import { toast } from "react-toastify"

const MailTemplateList = () => {
    const navigate = useNavigate()

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.client !== undefined && !permissions.client) {
            toast.error("You don't have permission to view mail templates!")
            navigate("/")
        }
    }, [permissions])

    const handleCreate = async e => {
        e.preventDefault(),
        navigate("/mailtemplate/create")
    }

    const columns =  [{
        Header: "Id",
        accessor: "id",
        Filter: NumberRangeColumnFilter,
        filter: 'between'
    }, {
        Header: "Name",
        accessor: "name"
    }, {
        Header: "Edit",
        UrlPart: "mailtemplate"
    }, {
        Header: "Delete",
        UrlPart: "mailtemplate/delete"
    }]

    const [mailTemplates, setMailTemplates] = useState([])

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/mailtemplates", {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(res => {
            if(res.data === null || res.data === "") {
                return
            }
            setMailTemplates(res.data)
        }).catch(_ => {
            toast.error("Unable to load mail templates!")
        })
    }, [])

    return (
        <div>
            <button className="btn btn-primary" onClick={handleCreate}>Create mail template</button>

            <BasicTableComponent columns={columns} data={mailTemplates} />
        </div>
    )
}

export default MailTemplateList