import axios from "axios"
import FormButton from "../../components/FormButton"
import { getToken } from "../../user/User"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const ProviderDetailView = ({ id, name, setName }) => {
    const navigate = useNavigate()

    const onSave = async e => {
        e.preventDefault()

        axios.put(process.env.REACT_APP_API_URL + "/api/providers/" + id, JSON.stringify({
            "name": name
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            toast.success("Saved provider " + name + " (#" + id + ").")
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 404) {
                    toast.error("Unable to find provider #" + id + ".")
                    return
                } else if(statusCode === 409) {
                    toast.error("There is a provider with the same name.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    const onDelete = async e => {
        e.preventDefault()
        if (confirm('Do you really want to delete this provider?')) {
            axios.delete(process.env.REACT_APP_API_URL + "/api/providers/" + id, { headers: {
                "Authorization": "Bearer " + getToken()
            }}).then(_ => {
                toast.success("Deleted provider " + name + " #" + id + ".")
                navigate("/provider")
            }).catch(error => {
                if (error.response) {
                    var statusCode = error.response.status
                    if(statusCode === 400) {
                        toast.error("Invalid request.")
                        return
                    } else if(statusCode === 404) {
                        toast.error("Unable to find provider #" + id + ".")
                        return
                    }
                }
                toast.error("Internal error.")
            })
        }
    }

    return (
        <>
            <form className="form-horizontal">
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <FormButton label="Name" id="name" required="required" value={name} setValue={setName} icon="fa-solid fa-tv" labelSize="col-lg-3" size="col-lg-7" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        <button className="btn btn-primary" onClick={onSave}>Save</button>
                        <button className="btn btn-danger" onClick={onDelete}>Delete</button>
                    </div>
                </div>
            </form>
        </>
    )
}
   
export default ProviderDetailView