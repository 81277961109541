import md5 from "md5"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getId, useCurrentUser, usePermissions } from "../user/User"
import Logo from './../img/logo.png'

const Sidebar = () => {
  const [ sidebar, setSidebar ] = useState("")
  const { id, email, name } = useCurrentUser()
  const { permissions } = usePermissions(getId())

  useEffect(() => {
    setSidebar(<>
      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="image">
          { permissions.user !== undefined && permissions.user ?
            <Link to={"/user/" + id}>
              <img src={email === null ? null : "https://www.gravatar.com/avatar/" + md5(email.toString().toLowerCase())} className="img-circle elevation-2" alt="User" />
            </Link> :
            <a>
              <img src={email === null ? null : "https://www.gravatar.com/avatar/" + md5(email.toString().toLowerCase())} className="img-circle elevation-2" alt="User" />
            </a>
          }
        </div>
        <div className="info">
          { permissions.user !== undefined && permissions.user ?
            <Link to={"/user/" + id}>
              {name}
            </Link> :
            <a>
              {name}
            </a>
          }
        </div>
      </div>
  
      <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          { permissions.user !== undefined && permissions.user ?
            <li className="nav-item">
              <Link to="/user" className="nav-link">
                <i className="nav-icon fa-solid fa-users"></i>
                <p>Users</p>
              </Link>
            </li>
            : ""
          }
          { permissions.client !== undefined && permissions.client ?
            <>
              <li className="nav-item">
                <Link to="/client" className="nav-link">
                  <i className="nav-icon fa-solid fa-person"></i>
                  <p>Clients</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/provider" className="nav-link">
                  <i className="nav-icon fa-solid fa-tv"></i>
                  <p>Providers</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/mailtemplate" className="nav-link">
                  <i className="nav-icon fa-solid fa-envelope"></i>
                  <p>Mail templates</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/automaticmailing" className="nav-link">
                  <i className="nav-icon fa-solid fa-envelopes-bulk"></i>
                  <p>Automatic mailing</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/mailing" className="nav-link">
                  <i className="nav-icon fa-solid fa-envelopes-bulk"></i>
                  <p>Mailing</p>
                </Link>
              </li>
            </>
            : ""
          }
        </ul>
      </nav>
    </>)
  }, [permissions, id, name, email])

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <Link to="/" className="brand-link">
        <img src={Logo} alt="Logo" className="brand-image img-circle elevation-3" />
        <span className="brand-text font-weight-light">{process.env.REACT_APP_APP_NAME}</span>
      </Link>
  
      <div className="sidebar">
        {sidebar}
      </div>
    </aside>
  )
}

export default Sidebar