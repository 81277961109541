import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Header from './Header'
import Dashboard from '../pages/Dashboard'
import UserList from '../pages/user/UserList'
import UserEdit from '../pages/user/UserEdit'
import UserCreate from '../pages/user/UserCreate'
import NotFound from '../pages/404'
import Footer from './Footer'
import { isLoggedIn } from "../user/User"
import Settings from '../pages/settings/Settings'
import Enable2FA from '../pages/settings/Enable2FA'
import ClientList from '../pages/client/ClientList'
import ClientCreate from '../pages/client/ClientCreate'
import ClientDelete from '../pages/client/ClientDelete'
import ClientEdit from '../pages/client/ClientEdit'
import ProviderList from '../pages/provider/ProviderList'
import ProviderCreate from '../pages/provider/ProviderCreate'
import ProviderDelete from '../pages/provider/ProviderDelete'
import ProviderEdit from '../pages/provider/ProviderEdit'
import AutomaticMailingList from '../pages/automaticmailing/AutomaticMailingList'
import AutomaticMailingCreate from '../pages/automaticmailing/AutomaticMailingCreate'
import AutomaticMailingDelete from '../pages/automaticmailing/AutomaticMailingDelete'
import AutomaticMailingEdit from '../pages/automaticmailing/AutomaticMailingEdit'
import MailingCreate from '../pages/mailing/MailingCreate'
import MailTemplateList from '../pages/mailtemplate/MailTemplateList'
import MailTemplateCreate from '../pages/mailtemplate/MailTemplateCreate'
import MailTemplateDelete from '../pages/mailtemplate/MailTemplateDelete'
import MailTemplateEdit from '../pages/mailtemplate/MailTemplateEdit'

function Wrapper() {
    const navigate = useNavigate()
    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/login")
        }
    })

    return (
        <div className="wrapper">
            <Navbar />

            <Sidebar />

            <div className="content-wrapper">
                <Header />

                <section className="content">
                    <div className="container-fluid">
                        <Routes>
                            <Route exact path="/" element={<Dashboard />} />

                            <Route exact path="/user" element={<UserList />} />
                            <Route exact path="/user/create" element={<UserCreate />} />
                            <Route path="/user/:id" element={<UserEdit />} />

                            <Route exact path="/settings" element={<Settings />} />
                            <Route exact path="/settings/enable2fa" element={<Enable2FA />} />

                            <Route exact path="/client" element={<ClientList />} />
                            <Route exact path="/client/create" element={<ClientCreate />} />
                            <Route path="/client/delete/:id" element={<ClientDelete />} />
                            <Route path="/client/:id" element={<ClientEdit />} />

                            <Route exact path="/provider" element={<ProviderList />} />
                            <Route exact path="/provider/create" element={<ProviderCreate />} />
                            <Route path="/provider/delete/:id" element={<ProviderDelete />} />
                            <Route path="/provider/:id" element={<ProviderEdit />} />

                            <Route exact path="/mailtemplate" element={<MailTemplateList />} />
                            <Route exact path="/mailtemplate/create" element={<MailTemplateCreate />} />
                            <Route path="/mailtemplate/delete/:id" element={<MailTemplateDelete />} />
                            <Route path="/mailtemplate/:id" element={<MailTemplateEdit />} />

                            <Route exact path="/automaticmailing" element={<AutomaticMailingList />} />
                            <Route exact path="/automaticmailing/create" element={<AutomaticMailingCreate />} />
                            <Route path="/automaticmailing/delete/:id" element={<AutomaticMailingDelete />} />
                            <Route path="/automaticmailing/:id" element={<AutomaticMailingEdit />} />

                            <Route exact path="/mailing" element={<MailingCreate />} />

                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </div>
                </section>
            </div>

            <Footer />
        </div>
    )
}

export default Wrapper
