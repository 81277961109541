import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router"
import { getId, usePermissions } from "../../user/User"
import { toast } from "react-toastify"
import { useEffect } from "react"
import { useProvider } from '../../provider/Provider'
import ProviderDetailView from './ProviderDetailView'

const ProviderEdit = () => {
    const navigate = useNavigate()

    let { id } = useParams()
    let { name, setName } = useProvider(id)

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.client !== undefined && !permissions.client) {
            toast.error("You don't have permission to edit providers!")
            navigate("/")
        }
    }, [permissions])

    const handleBack = async e => {
        e.preventDefault()
        navigate("/provider")
    }

    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Back to overview</button><br /><br />

            <ProviderDetailView id={id} name={name} setName={setName} />
        </>
    )
}

export default ProviderEdit