const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="float-right d-none d-sm-block">
        
      </div>
      <strong>{process.env.REACT_APP_APP_NAME}</strong>
    </footer>
  )
}
 
export default Footer