import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router"
import { getId, usePermissions } from "../../user/User"
import { toast } from "react-toastify"
import { useEffect } from "react"
import MailTemplateDetailView from './MailTemplateDetailView'
import { useMailTemplate } from '../../mailtemplate/MailTemplate'

const MailTemplateEdit = () => {
    const navigate = useNavigate()

    let { id } = useParams()
    let { name, setName, subject, setSubject, body, setBody } = useMailTemplate(id)

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.client !== undefined && !permissions.client) {
            toast.error("You don't have permission to edit mail templates!")
            navigate("/")
        }
    }, [permissions])

    const handleBack = async e => {
        e.preventDefault()
        navigate("/mailtemplate")
    }

    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Back to overview</button><br /><br />

            <MailTemplateDetailView id={id} name={name} setName={setName} subject={subject} setSubject={setSubject} body={body} setBody={setBody} />
        </>
    )
}

export default MailTemplateEdit