import useBreadcrumbs from 'use-react-router-breadcrumbs'

const Header = () => {
  const routes = [
    { path: '/', props: { value: 'Home' }},
    { path: '/user', props: { value: 'User' }},
    { path: '/user/create', props: { value: 'Create user' }},
    { path: '/user/*', props: { value: 'Edit user' }},
    { path: '/settings', props: { value: 'Settings' }},
    { path: '/settings/enable2fa', props: { value: 'Enable two factor authentication' }},
    { path: '/client', props: { value: 'Client' }},
    { path: '/client/create', props: { value: 'Create client' }},
    { path: '/client/delete/*', props: { value: 'Delete client' }},
    { path: '/client/*', props: { value: 'Edit client' }},
    { path: '/provider', props: { value: 'Provider' }},
    { path: '/provider/create', props: { value: 'Create provider' }},
    { path: '/provider/delete/*', props: { value: 'Delete provider' }},
    { path: '/provider/*', props: { value: 'Edit provider' }},
    { path: '/mailtemplate', props: { value: 'Mail template' }},
    { path: '/mailtemplate/create', props: { value: 'Create mail template' }},
    { path: '/mailtemplate/delete/*', props: { value: 'Delete mail template' }},
    { path: '/mailtemplate/*', props: { value: 'Edit mail template' }},
    { path: '/automaticmailing', props: { value: 'Automatic mailing' }},
    { path: '/automaticmailing/create', props: { value: 'Create automatic mailing' }},
    { path: '/automaticmailing/delete/*', props: { value: 'Delete automatic mailing' }},
    { path: '/automaticmailing/*', props: { value: 'Edit automatic mailing' }},
    { path: '/mailing', props: { value: 'Mailing' }},
    { path: '/*', props: { value: '404 Not found' }},
  ]
  const breadcrumbs = useBreadcrumbs(routes)
  const active = breadcrumbs[breadcrumbs.length - 1].value

  return (
      <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h2>{active}</h2>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              {breadcrumbs.map((breadcrumb) => active === breadcrumb.value ? null : <li className="breadcrumb-item" key={breadcrumb.value}><a href={breadcrumb.key}>{breadcrumb.value}</a></li>)}
              <li className="breadcrumb-item active" key={active}>{active}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  )
}
   
export default Header