import axios from "axios"
import { useEffect, useState } from "react"
import FormButton from "../../components/FormButton"
import { getToken } from "../../user/User"
import Select from "react-select"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const ClientDetailView = ({ id, username, setUsername, emailAddress, setEmailAddress, provider, setProvider, duration, setDuration, expiryDate, setExpiryDate }) => {
    const navigate = useNavigate()

    const [providerSelect, setProviderSelect] = useState(<></>)
    const [durationSelect, setDurationSelect] = useState(<></>)

    const durationOptions = [
        {
            value: 0,
            label: ""
        },
        {
            value: 1,
            label: "1 month"
        },
        {
            value: 3,
            label: "3 months"
        },
        {
            value: 6,
            label: "6 months"
        },
        {
            value: 12,
            label: "12 months"
        },
        {
            value: 24,
            label: "24 months"
        }
    ]

	useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/providers", { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            const options = []
            if(res.data != null) {
                res.data.forEach((provider, _) => {
                    options.push({
                        value: provider.id,
                        label: provider.name
                    })
                })
            }

            setProviderSelect(<Select options={options} onChange={(choice) => setProvider(choice.value)} value={options.filter(option => option.value == provider)} />)
        }).catch(error => {
            console.log(error)
            toast.error("Unable to load providers")
        })
    }, [provider])

	useEffect(() => {
        setDurationSelect(<Select options={durationOptions} onChange={(choice) => setDuration(choice.value)} value={durationOptions.filter(option => option.value == duration)} />)
    }, [duration])
    
    const onSave = async e => {
        e.preventDefault()

        var expiryDateTimestamp = expiryDate === "" ? 0 : Math.floor(new Date(expiryDate).getTime() / 1000)
        if(expiryDateTimestamp < 0) {
            expiryDateTimestamp = 0
        }
    
        axios.put(process.env.REACT_APP_API_URL + "/api/clients/" + id, JSON.stringify({
            "username": username,
            "email_address": emailAddress,
            "provider": parseInt(provider),
            "duration": duration,
            "expiry_date": expiryDateTimestamp
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            toast.success("Saved client " + username + " (#" + id + ").")
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 404) {
                    toast.error("Unable to find client #" + id + ".")
                    return
                } else if(statusCode === 409) {
                    toast.error("There is a client with the same username and email address.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    const onDelete = async e => {
        e.preventDefault()
        if (confirm('Do you really want to delete this client?')) {
            axios.delete(process.env.REACT_APP_API_URL + "/api/clients/" + id, { headers: {
                "Authorization": "Bearer " + getToken()
            }}).then(_ => {
                toast.success("Deleted client " + username + " #" + id + ".")
                navigate("/client")
            }).catch(error => {
                if (error.response) {
                    var statusCode = error.response.status
                    if(statusCode === 400) {
                        toast.error("Invalid request.")
                        return
                    } else if(statusCode === 404) {
                        toast.error("Unable to find client #" + id + ".")
                        return
                    }
                }
                toast.error("Internal error.")
            })
        }
    }

    return (
        <>
            <form className="form-horizontal">
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <FormButton label="Username" id="username" required="required" value={username} setValue={setUsername} icon="fa-solid fa-user" labelSize="col-lg-3" size="col-lg-7" />
                                </div>
                                <div className="col-lg-6 col-12">
                                    <FormButton label="Email address" id="emailAddress" required="required" type="email" value={emailAddress} setValue={setEmailAddress} icon="fa-solid fa-envelope" labelSize="col-lg-3" size="col-lg-7" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-3 col-12">
                                    <b>Provider</b>
                                    {providerSelect}
                                    <br />
                                </div>
                                <div className="col-lg-3 col-12">
                                    <b>Duration</b>
                                    {durationSelect}
                                    <br />
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <FormButton label="Expiry date" id="expiryDate" type="date" value={expiryDate} setValue={setExpiryDate} icon="fa-solid fa-clock" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        <button className="btn btn-primary" onClick={onSave}>Save</button>
                        <button className="btn btn-danger" onClick={onDelete}>Delete</button>
                    </div>
                </div>
            </form>
        </>
    )
}
   
export default ClientDetailView