import axios from "axios"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getId, getToken, usePermissions } from "../../user/User"
import BasicTableComponent, { NumberRangeColumnFilter, SelectColumnFilter } from "../../components/basic.table"
import { toast } from "react-toastify"

const ClientList = () => {
    const navigate = useNavigate()

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.client !== undefined && !permissions.client) {
            toast.error("You don't have permission to view clients!")
            navigate("/")
        }
    }, [permissions])

    const handleCreate = async e => {
        e.preventDefault(),
        navigate("/client/create")
    }

    const columns =  [{
        Header: "Id",
        accessor: "id",
        Filter: NumberRangeColumnFilter,
        filter: 'between'
    }, {
        Header: "Email address",
        accessor: "email_address"
    }, {
        Header: "Username",
        accessor: "username"
    }, {
        Header: "Provider",
        accessor: "provider_name",
        Filter: SelectColumnFilter,
        filter: 'includes'
    }, {
        Header: "Duration",
        accessor: "duration",
        Filter: NumberRangeColumnFilter,
        filter: 'between'
    }, {
        Header: "Expiry date",
        accessor: (row, _) => {
            if(row.expiry_date <= 0) {
                return ""
            }
            return new Date(row.expiry_date * 1000).toLocaleDateString("de-DE", { year: 'numeric', month: 'numeric', day: 'numeric' })
        }
    }, {
        Header: "Edit",
        UrlPart: "client"
    }, {
        Header: "Delete",
        UrlPart: "client/delete"
    }]

    const [clients, setClients] = useState([])

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/clients", {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(res => {
            if(res.data === null || res.data === "") {
                return
            }
            setClients(res.data)
        }).catch(_ => {
            toast.error("Unable to load clients!")
        })
    }, [])

    return (
        <div>
            <button className="btn btn-primary" onClick={handleCreate}>Create client</button>

            <BasicTableComponent columns={columns} data={clients} />
        </div>
    )
}

export default ClientList
