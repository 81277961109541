import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Select from 'react-select'
import { toast } from "react-toastify"
import FormButton from "../../components/FormButton"
import { getId, getToken, usePermissions } from "../../user/User"

const ProviderCreate = () => {
    const navigate = useNavigate()

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.client !== undefined && !permissions.client) {
            toast.error("You don't have permission to create providers!")
            navigate("/")
            return
        }
    }, [permissions])

    const [name, setName] = useState("")

    async function createProvider() {
        axios.post(process.env.REACT_APP_API_URL + "/api/providers", JSON.stringify({
            "name": name
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            toast.success("Created provider " + name + " (#" + res.data.id + ").")
            navigate("/provider/" + res.data.id)
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 409) {
                    toast.error("There is a provider with the same name.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        createProvider()
    }

    const handleBack = async e => {
        e.preventDefault()
        navigate("/provider")
    }

    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Back to overview</button><br /><br />

            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <FormButton label="Name" id="name" required="required" value={name} setValue={setName} icon="fa-solid fa-tv" labelSize="col-lg-3" size="col-lg-7" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        <button type="submit" className="btn btn-success">Create provider</button>
                    </div>
                </div>
            </form>
        </>
    )
}
   
export default ProviderCreate