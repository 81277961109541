import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getToken } from '../user/User'

export const useClient = (id) => {
    const [username, setUsername] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [provider, setProvider] = useState(0)
    const [duration, setDuration] = useState(0)
    const [expiryDate, setExpiryDate] = useState("")

    useEffect(() => {
        if(id !== null && id !== undefined) {
            const abortCont = new AbortController()
            
            axios.get(process.env.REACT_APP_API_URL + "/api/clients/" + id, {
                signal: abortCont.signal,
                headers: {
                    "Authorization": "Bearer " + getToken()
                }
            })
            .then(res => {
                var data = res.data

                setUsername(data.username)
                setEmailAddress(data.email_address)
                setProvider(data.provider)
                setDuration(data.duration)
                if(data.expiry_date != 0) {
                    var date = new Date(data.expiry_date * 1000)
                    var localDate = date.toLocaleDateString()
                    console.log(localDate)
                    var dateParts
                    var dateString
                    if(localDate.includes(".")) {
                        //dd.mm.yyyy
                        dateParts = localDate.split(".")
                        dateString = dateParts[2] + "-" + (dateParts[1].length == 1 ? "0" + dateParts[1] : dateParts[1]) + "-" + (dateParts[0].length == 1 ? "0" + dateParts[0] : dateParts[0])
                    } else if(localDate.includes("/")) {
                        //dd/mm/yyyy
                        dateParts = localDate.split("/")
                        dateString = dateParts[2] + "-" + (dateParts[1].length == 1 ? "0" + dateParts[1] : dateParts[1]) + "-" + (dateParts[0].length == 1 ? "0" + dateParts[0] : dateParts[0])
                    } else if(localDate.includes("-")) {
                        //yyyy-mm-dd 1987-05-17
                        dateParts = localDate.split("-")
                        dateString = dateParts[0] + "-" + (dateParts[1].length == 1 ? "0" + dateParts[1] : dateParts[1]) + "-" + (dateParts[2].length == 1 ? "0" + dateParts[2] : dateParts[2])
                    }
                    if(dateString != undefined) {
                        setExpiryDate(dateString)
                    }
                }
            }).catch(err => {
                if(err.message === "canceled") {
                    return
                }
                toast.error("Internal error")
            })
        
            return () => abortCont.abort()
        }
    }, [id])

    return { id, username, setUsername, emailAddress, setEmailAddress, provider, setProvider, duration, setDuration, expiryDate, setExpiryDate }
}