import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getToken } from '../user/User'

export const useMailTemplate = (id) => {
    const [name, setName] = useState("")
    const [subject, setSubject] = useState("")
    const [body, setBody] = useState("")

    useEffect(() => {
        if(id !== null && id !== undefined) {
            const abortCont = new AbortController()
            
            axios.get(process.env.REACT_APP_API_URL + "/api/mailtemplates/" + id, {
                signal: abortCont.signal,
                headers: {
                    "Authorization": "Bearer " + getToken()
                }
            })
            .then(res => {
                var data = res.data

                setName(data.name)
                setSubject(data.subject)
                setBody(data.body)
            }).catch(err => {
                if(err.message === "canceled") {
                    return
                }
                toast.error("Internal error")
            })
        
            return () => abortCont.abort()
        }
    }, [id])

    return { id, name, setName, subject, setSubject, body, setBody }
}